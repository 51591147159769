export const Components = {
    Main: () => import('@/components/MainComponent.vue'),
    History: () => import('@/components/HistoryComponent.vue'),
    Trials: () => import('@/components/TrialsComponent.vue'),
    Contacts: () => import('@/components/ContactsComponent.vue'),
    Galery: () => import('@/components/GaleryComponent.vue'),
    Archive: () => import('@/components/ArchiveComponent.vue'),
    Archive2019: () => import('@/components/subcomponents/Archive2019Component.vue'),
    Archive2020: () => import('@/components/subcomponents/Archive2020Component.vue'),
    Archive2021: () => import('@/components/subcomponents/Archive2021Component.vue'),
    Archive2022: () => import('@/components/subcomponents/Archive2022Component.vue'),
    Archive2023: () => import('@/components/subcomponents/Archive2023Component.vue')
}
