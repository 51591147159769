import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { Components } from "@/components";

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    { path: '/', component: Components.Main, name: 'HH 2024 Nagymaros'},
    { path: '/history', component: Components.History, name: 'Történet'},
    { path: '/trials', component: Components.Trials, name: 'Itt kezdődött'},
    { path: '/hh-hungary', beforeEnter: () => window.location.href = 'https://hazassaghete.hu/', name: 'HH Magyarország' },
    { path: '/contacts', component: Components.Contacts, name: 'Team / Kapcsolat'},
    { path: '/galery', component: Components.Galery, name: 'Galéria'},
    {
      path: '/archive',
      component: Components.Archive,
      name: 'Archívum',
      children: [
        { path: '2019', component: Components.Archive2019 },
        { path: '2020', component: Components.Archive2020 },
        { path: '2021', component: Components.Archive2021 },
        { path: '2022', component: Components.Archive2022 },
        { path: '2023', component: Components.Archive2023 },
      ]
    }
  ]
});

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
